import React, { useReducer, useEffect, useMemo, useCallback } from 'react'
import PropTypes, { InferProps } from 'prop-types'

import { AuthProvider, stateDefault, reducers, TAuthState } from '@hooks/useAuth'
import { User } from '@ts-types/User'
import { setItem, getItem, removeItem } from '@utils/localStorage'
import getURLSearchParams from '@utils/getURLSearchParams'

const propTypes = {
  children: PropTypes.node.isRequired,
}

type Props = InferProps<typeof propTypes>

function WrapperAuthProvider({ children }: Props) {
  const [state, dispatch]: [TAuthState, any] = useReducer(reducers, stateDefault)

  const setAccessTokenToLocalStorage = useCallback((token: string): string => {
    const { accessToken } = setItem('accessToken', token)
    return accessToken
  }, [])

  const authUtils = useMemo(
    () => ({
      logIn: async (token: string): Promise<void> => {
        const accessToken = setAccessTokenToLocalStorage(token)
        dispatch({ type: 'LOG_IN', payload: { accessToken } })
      },
      setVerifiedAccessToken: async (token: string): Promise<void> => {
        const accessToken = setAccessTokenToLocalStorage(token)
        dispatch({ type: 'SET_VERIFIED_ACCESS_TOKEN', payload: { accessToken } })
      },
      signOut: async (): Promise<void> => {
        removeItem('accessToken')
        dispatch({ type: 'LOG_OUT' })
      },
      restoreToken: (accessToken: string): void => {
        dispatch({ type: 'RESTORE_TOKEN', payload: { accessToken } })
      },
      restoreUser: (profile: User): void => {
        dispatch({ type: 'RESTORE_USER', payload: { profile } })
      },
      restoreUserFailed: () => {
        dispatch({ type: 'RESTORE_USER_FAILED' })
      },
      tokenIsNotVerified: () => {
        dispatch({ type: 'TOKEN_IS_NOT_VERIFIED' })
      },
      whoAmIIsLoading: (loading: boolean) => {
        dispatch({ type: 'WHO_AM_I_IS_LOADING', payload: { loading } })
      },
      appIsLoading: () => {
        dispatch({ type: 'APP_IS_LOADING' })
      },
    }),
    [setAccessTokenToLocalStorage, dispatch]
  )

  useEffect(() => {
    const setAccessToken = (): void => {
      const { accessToken = '' } = getItem('accessToken')
      authUtils.restoreToken(accessToken)
    }

    const setRedirectTo = () => {
      const urlParams: URLSearchParams = getURLSearchParams()
      const redirectTo: string = urlParams.get('redirectTo')

      dispatch({
        type: 'SET_REDIRECT_TO',
        payload: {
          redirectTo: redirectTo ? decodeURIComponent(redirectTo) : '',
        },
      })
    }

    const bootstrapAsync = (): void => {
      setAccessToken()
      setRedirectTo()
    }

    bootstrapAsync()
  }, [])

  return <AuthProvider value={{ ...state, ...authUtils }}>{children}</AuthProvider>
}

WrapperAuthProvider.propTypes = propTypes

export default WrapperAuthProvider
